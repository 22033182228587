import React from 'react';
import Splash from '../../components/splash/Splash';
import Content from '../../components/content/Content';
import ToothIcon from '../../assets/svg/tooth.svg';
import HandshakeIcon from '../../assets/svg/hands.svg';
import styles from './Home.module.scss';

const Home = () => {
    return (
        <React.Fragment>
            <Splash>
                <h1 className={styles.splashHeading}>Dentallabor Zollondz</h1>
            </Splash>
            <Content image={ToothIcon} imageFloat='right' title='Willkommen'>
                <p>Ich bin spezialisiert auf ästhetisch funktionelle Restaurationen.</p>
                <p>Durch meine berufliche Qualifikation, über 25-jährige Spezialisierung und ständige Weiterbildung entstehen in meinem Labor Arbeiten von hoher Qualität und Sicherheit. 
                    Unter Anwendung der klassischen Verfahren verbunden mit der CAD/CAM-Technik und modernen Materialien fertige ich sehr natürlich wirkenden Zahnersatz.</p>
            </Content>
            <Content image={HandshakeIcon} imageFloat='left' title='Kundenzufriedenheit'>
                <p>Schöne Zähne spielen eine wichtige Rolle und machen attraktiv. Entscheidend ist, dass der Patient sich gut fühlt.
                    Das Ziel muss aber nicht immer die ebenmäßige Reihe sein. Gerade kleine Disharmonien der Zähne zeichnen oft ein
                    interressantes attraktives Gesicht aus.</p>
                <p>Ein schöner natürlicher Zahnersatz &quot;entsteht&quot;. Er unterliegt einem Prozess bei dem eine gute Kommunikation zwischen
                    dem  Zahnarzt, Zahntechniker und Patient unerlässlich ist.</p>
            </Content>
        </React.Fragment>
    );
};

export default Home;